import * as React from "react";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import HubspotForm from "react-hubspot-form";

const Wrap = styled.div`
  margin-bottom: 4rem;
`;

const AdvocacyForm = () => {
  return (
    <Wrap>
      <Container>
        <Row>
          <Col xs={1} sm={1}>
          </Col>
          <Col>
        <h2 style={{marginBottom: '1rem'}}>Join Us!</h2>
        <HubspotForm
          portalId="21124615"
          formId="ca304b09-382e-4898-9004-522af237550b"
          // onSubmit={() => console.log("Submitted!")}
          // onReady={(form) => console.log("Form ready!")}
          loading={<div>Loading...</div>}
        />
          </Col>
        </Row>
      </Container>
    </Wrap>
  );
};

export default AdvocacyForm;
