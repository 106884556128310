import * as React from "react";
import { Card, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../vars/palette";
import { Type } from "./Typography";
import { WmkLink } from "wmk-link";
import get from "lodash/get";
import { Img, WmkImage } from "wmk-image";
import { ContactInfo } from "../../classes/ContactInfo";
import { FaRegCalendarAlt } from "react-icons/fa";

const StyledCard = styled(Card)`
  border-radius: 5px;
  border: none;
  // box-shadow: 0px 3px 10px #00000014;
  padding: 35px;
  width: 342px;

  .img-container {
    position: relative;
    max-height: 163px;
    margin-bottom: 20px;
    img {
      border-radius: 5px;
    }
  }

  .link {
    display: inline-block;

    a {
      color: ${colors.green.hex};
      font-size: 17px;
      text-decoration: none;
    }

    &:not(:last-child) {
      padding-right: 8px;
    }
  }

  .jobtitle {
    color: ${colors.black.hex};
    font-size: 15px;
    text-transform: none;
    white-space: pre-wrap;
  }
  .address-line {
    margin-bottom: 0;
    font-size: 15px;
    &:first-of-type {
      margin-top: 10px;
    }
    &:last-of-type {
      margin-bottom: 5px;
    }
  }
  .contactList {
    display: block;
    a {
      color: ${colors.green.hex};
      font-size: 16px;
    }
  }
  @media only screen and (max-width: 342px) {
    width: 100%;
  }
`;

const StyledHeadshotBar = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  width: 82px;
  position: absolute;
  height: 10px;
  bottom: 0;
  left: 0;
`;

const HeadshotBar = ({ color }: { color: string }) => {
  return <StyledHeadshotBar color={color} />;
};

/**
 *
 */
type RegEndCardProps = {
  image: Img;
  name: string;
  job: string;
  addressArr: string[];
  contact: ContactInfo;
  bio: string;
  regionColor: { normal: string; hover: string; active: string };
  region: string;
};

const RegEngCard = ({
  image,
  name,
  region,
  job,
  addressArr,
  contact,
  bio,
  regionColor
}: RegEndCardProps) => {
  const splitRegion = region.split(":");
  const seperatedRegion = splitRegion[1];
  const regionNoSpace = seperatedRegion.replace(/\s+/g, '');
  const updatedRegion =
    seperatedRegion && !seperatedRegion.includes("Canada")
      ? `${seperatedRegion} STATES`
      : seperatedRegion;
  return (
    <StyledCard>
      <Row className="flex-column no-gutters">
        <Col>
          <div className="img-container">
            <WmkImage image={image} />
            <HeadshotBar color={regionColor.normal} />
          </div>
        </Col>
        <Col>
          <Type.EngRegion>
            {updatedRegion ? updatedRegion : region}
          </Type.EngRegion>
        </Col>
        <Col>
          <Type.EngHeadline>{name}</Type.EngHeadline>
        </Col>
        <Col>
          <span className="jobtitle">{job}</span>
        </Col>
        <Col>
          {addressArr &&
            addressArr.map((line, i) => {
              return (
                <p className="address-line" key={line + i}>
                  {line}
                </p>
              );
            })}
        </Col>
        <Row>
          <Col>
            {contact &&
              contact.list.map((item, i) => {
                return (
                  <div className="contactList" key={item.type + i + item.text}>
                    <WmkLink
                      to={item.to}
                      target={item.target}
                      tel={get(item, `type`) === "tel" ? true : undefined}
                      mailto={
                        get(item, `type`) === "mailto" ? true : undefined
                      }>
                      {item.text}
                    </WmkLink>
                  </div>
                );
              })}
          </Col>
        </Row>
        {bio && (
          <Row>
            <Col>
              <div className="contactList">
                <WmkLink to={bio} target="_blank">
                  Read Bio
                </WmkLink>
              </div>
            </Col>
          </Row>
        )}
        <Col>
          <div className="contactList">
            <WmkLink to={`/news/upcoming-events/${regionNoSpace.toLocaleLowerCase()}`}>
              <FaRegCalendarAlt /> Let's Connect - Where I'll Be
            </WmkLink>
          </div>
        </Col>
      </Row>
    </StyledCard>
  );
};

export default RegEngCard;
