import * as React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import HubspotForm from "react-hubspot-form";

const Wrap = styled.div`
  margin-bottom: 4rem;
  h2,
  h3,
  p {
    text-align: center;
    max-width: 800px;
    margin: auto;
  }
  h2 {
    margin-bottom: 1rem;
  }
`;

const OrderInstallationGuidePage = () => {
  return (
    <Wrap>
      <Container>
        <h2>Order Installation Guides</h2>
        <p style={{ marginBottom: "2rem" }}>
          {`Fill out the form below to get your free DIPRA Ductile iron pipe
          installation guide, and the Polyethylene Encasement Installation Guide`}
        </p>
        <HubspotForm
          portalId="21124615"
          formId="4a27bb4b-b848-4a87-98d8-9af111c9cef3"
          // onSubmit={() => console.log("Submitted!")}
          // onReady={(form) => console.log("Form ready!")}
          loading={<div>Loading...</div>}
        />
      </Container>
    </Wrap>
  );
};

export default OrderInstallationGuidePage;
