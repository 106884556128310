import { Img } from "wmk-image";
import { AssetFile } from "../fragments/NodeBrandAssetFields";
import { StaffAssetQuery } from "../fragments/NodeStaffAssetFields";

export class StaffAsset {
  staffAssetId: string;
  image: Img;
  jobTitle: string;
  name: string;
  group: string;
  biographyPdf: AssetFile;
  biographyWordDoc: AssetFile;
  headshot150Dpi: AssetFile;
  headshot300Dpi: AssetFile;
  order: number;
  constructor(node: StaffAssetQuery) {
    const _node = { ...node };
    this.staffAssetId = _node.staffAssetId;
    this.image = new Img(_node.image);
    this.jobTitle = _node.jobTitle;
    this.name = _node.name;
    this.group = _node.group;
    this.biographyPdf = _node.biographyPdf;
    this.biographyWordDoc = _node.biographyWordDoc;
    this.headshot150Dpi = _node.headshot150Dpi;
    this.headshot300Dpi = _node.headshot300Dpi;
    this.order = _node.order;
  }
}

export class StaffAssets {
  /**
   * collection of NodeStaffFields results
   */
  list: StaffAsset[];
  length: number;
  groups: { [key: string]: StaffAsset[] };
  constructor(edges: { node: StaffAssetQuery }[]) {
    const staff = Array.isArray(edges)
      ? edges.map((e) => new StaffAsset(e.node))
      : [];
    this.list = staff;
    this.length = staff.length;
    this.groups = staff.reduce((groups, member) => {
      const _group = member.group;
      const group = _group.match(/staff/i)
        ? "Management"
        : "Regional Engineers";
      if (!groups[group]) {
        groups[group] = [];
      }
      groups[group].push(member);
      return groups;
    }, {});
  }
}