import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { WmkLink } from "wmk-link";
// import { slugifyTitle } from "wmk-tag";
import { Type } from "../components/ui/Typography";
import { searchResult } from "../components/util/searchResultInterface";
import { SearchContext } from "../context/SearchContext";
import {
  BsFileEarmark,
  BsFileEarmarkPdf,
  BsFileEarmarkPlay,
  BsGlobe,
  BsFileEarmarkText,
  BsFileEarmarkRuled
} from "react-icons/bs";
import { HiLink } from "react-icons/hi";
import { colors } from "../vars/palette";
import algoliasearch from "algoliasearch";

const SearchResultsContainer = styled(Container)<{ noresults: string }>`
  padding: 5rem 20% 5rem calc(20% + 2rem);
  .result-display {
    min-height: 5rem;
    padding-bottom: 1rem;
    position: relative;
  }
  a {
    color: ${colors.green.hex};
  }
  a:hover {
    color: ${colors.black.hex};
  }
  h2 {
    font-weight: 700;
    font-size: 20px;
  }
  // @media only screen and (max-width: 766px) {
  //   padding: 5rem 8rem 0 12rem;
  // }
  @media only screen and (max-width: 540px) {
    padding: ${({ noresults }) => {
      return noresults === "true" ? `2rem 4rem 3rem` : `5rem 4rem 3rem 6rem`;
    }};
  }
`;

const IconWrap = styled.div`
  position: absolute;
  left: -3.5rem;
  display: inline;
  top: 0.3rem;
  svg {
    margin-right: 1rem;
    transform: scale(1.1);
  }
  .globe {
    position: absolute;
    transform: scale(0.5);
    left: -0.5px;
    top: 1px;
  }
`;
const searchClient = algoliasearch(
  "ODQJXR6FON",
  "0e406faab78dc1d9c33a231cc3c27f2f"
);

const SearchResults = () => {
  const [searchRan, setSearchRan] = useState(false);
  const [searchResults, setSearchResults] = useState<searchResult[]>([]);
  // useEffect(() => {
  //   console.log(JSON.parse(sessionStorage.getItem("searchResults")));
  // }, []);

  // useEffect(() => {
  //   window.dataLayer = window.dataLayer || [];
  // },[])

  return (
    <SearchContext.Consumer>
      {(consumerData) => {
        // console.log("consumerData", consumerData);
        // const algoliasearch = require("algoliasearch");
        // // Connect and authenticate with your Algolia app
        // const client = algoliasearch(
        //   "ODQJXR6FON",
        //   "0e406faab78dc1d9c33a231cc3c27f2f"
        // );
        // // Create a new index and add a record
        // const sIndex = client.initIndex("dipra_source_index");
        // // Search for "query string" in the index "contacts"
        // const results = sIndex.search(consumerData?.searchInput).then(({ hits }) => {
        //   console.log("hits", hits)
        //   // setSearchResults(hits)
        //   return hits
        // })
        // handleResults(results)
        // // setSearchResults(results)

        const handleSubmit = () => {
          let aHits: searchResult[];
          const sIndex = searchClient.initIndex("dipra_source_index");
          // Search for "query string" in the index "contacts"
          sIndex
            .search(consumerData.searchInput)
            .then(({ hits }) => {
              aHits = hits as searchResult[];
            })
            .then(() => {
              setSearchResults(aHits);
            })
            .then(() => {
              setSearchRan(true);
            })
        };

        if (
          consumerData.searchInput &&
          consumerData.searchResults < 1 &&
          !searchRan
        ) {
          handleSubmit();
        }

        const ResultIcon = ({
          model,
          size
        }: {
          model: LinkModels;
          size: string | number;
        }) => {
          const PdfIconJSX = (
            <IconWrap title="PDF">
              <BsFileEarmarkPdf size={size} />
            </IconWrap>
          );
          const PageIconJSX = (
            <IconWrap title="Webpage">
              <BsFileEarmark className="earmark" size={size} />
              <BsGlobe className="globe" size={size} />
            </IconWrap>
          );
          const VidIconJSX = (
            <IconWrap title="Video">
              <BsFileEarmarkPlay size={size} />
            </IconWrap>
          );
          const DefaultIconJSX = (
            <IconWrap title="link">
              <HiLink size={size} />
            </IconWrap>
          );
          const NewsIconJSX = (
            <IconWrap title="News & Commentary">
              <BsFileEarmarkText size={size} />
            </IconWrap>
          );
          const ResourceIconJSX = (
            <IconWrap title="Technical Resources">
              <BsFileEarmarkRuled size={size} />
            </IconWrap>
          );
          let Icon;
          switch (model) {
            case "pdf":
              Icon = PdfIconJSX;
              break;
            case "doc":
              Icon = PdfIconJSX;
              break;
            case "news":
              Icon = NewsIconJSX;
              break;
            case "resource":
              Icon = ResourceIconJSX;
              break;
            case "page":
              Icon = PageIconJSX;
              break;
            case "video":
              Icon = VidIconJSX;
              break;
            default:
              Icon = DefaultIconJSX;
              break;
          }
          return Icon;
        };

        const resultConversion = (res: searchResult) => {
          const checkParent = (parent: string) =>
            parent && parent !== "/" ? parent : ``;
          return {
            to:
              // res.nodeType === "pdf"
              //   ? `/docs/${slugifyTitle(res.node.title)}`
              //   :
              res.nodeType === "doc"
                ? `/docs/${res.node.slug}`
                : res.nodeType === `resource`
                ? `/technical-resources${checkParent(res.node.parentPath)}/${
                    res.node.slug
                  }`
                : res.nodeType === "page"
                ? `${checkParent(res.node.parentPath)}/${res.node.slug}`
                : // : res.nodeType === "video"
                  // ? `https://youtu.be/${res.node.videoId}`
                  // : res.nodeType === "news"
                  // ? `/news/news-and-commentary/${res.node.slug}`
                  ``,
            text: res.node.title,
            model: res.nodeType,
            target:
              res.nodeType === "page" ||
              // res.nodeType === "news" ||
              res.nodeType === "resource"
                ? undefined
                : "blank"
          };
        };
        if (searchResults && searchResults.length) {
          searchResults.sort((a, b) => {
            const titleA = a.node.title.toUpperCase();
            const titleB = b.node.title.toUpperCase();
            if (
              (titleA.includes("FRENCH") || titleA.includes("SPANISH")) &&
              titleB.includes("ENGLISH")
            ) {
              return 1;
            } else if (
              titleA.includes("ENGLISH") &&
              (titleB.includes("FRENCH") || titleB.includes("SPANISH"))
            ) {
              return -1;
            } else {
              return 0;
            }
          });
        }
        return (
          <SearchResultsContainer
            fluid
            noresults={!searchResults?.length ? "true" : ""}>
            {searchResults && searchResults.length
              ? searchResults.map((result, i) => {
                  const finalResult = resultConversion(result);
                  const fullUrl = !finalResult.to.includes("//")
                    ? `https://dipra.org${finalResult.to}`
                    : finalResult.to;
                  return (
                    <Row
                      key={`${result.node?.title} ${i}`}
                      className="result-display">
                      <Col>
                        <ResultIcon model={finalResult.model} size="2rem" />
                        <Type.IconCopyHeadline
                          style={{
                            marginBottom: "1rem",
                            textTransform: "uppercase"
                          }}>
                          <WmkLink to={finalResult.to} target="_blank">
                            {finalResult.text}
                          </WmkLink>
                        </Type.IconCopyHeadline>
                        <Type.P>
                          <WmkLink to={finalResult.to} target="_blank">
                            {fullUrl}
                          </WmkLink>
                        </Type.P>

                        <Type.P>{result.node?.metaDescription}</Type.P>
                        <div style={{ paddingBottom: "2rem" }}></div>
                      </Col>
                    </Row>
                  );
                })
              : null}
            {!searchRan ? (
              <div className="noResultsMessage" style={{ textAlign: "center" }}>
                <Type.EngHeadline style={{ textAlign: "center" }}>
                  {`Loading Results`}
                </Type.EngHeadline>
              </div>
            ) : !searchResults?.length ? (
              <>
                <div
                  className="noResultsMessage"
                  style={{ textAlign: "center" }}>
                  <Type.EngHeadline style={{ textAlign: "center" }}>
                    {`No search results for "${consumerData.searchInput}"`}
                  </Type.EngHeadline>
                </div>
              </>
            ) : null}
          </SearchResultsContainer>
        );
      }}
    </SearchContext.Consumer>
  );
};

export default SearchResults;
