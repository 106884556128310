import * as React from 'react';
import styled from 'styled-components'
import get from 'lodash/get'
import { WmkLink } from 'wmk-link';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Type } from '../../../components/ui/Typography';
import DipraButton from '../../../components/ui/DipraButton';
import { colors } from '../../../vars/palette';
 
const Wrap = styled.div`
  * {
  }
  position: relative;
  width: 100%;
  // height: 18rem;
  padding: 2rem 7vw;
  margin: 1rem 0;
  box-shadow: 0px 0px 8px rgba(0,0,0,0.2);
`
const PlusWrap = styled.div`
  position: absolute;
  top: 3.25rem;
  left: 3vw;
  font-size: 1.5rem;
`
const LogoWrap = styled.div`
  height: 5rem;
  ${({ name }) => name === "American Water Works Association (AWWA)" ? `padding: 1.5rem 0;` : ``}
  a {
    height: 100%;
    .gatsby-image-wrapper {    
      height: 100%;
      width: 100%;      
      img {
        width: auto;  
        max-width: 100%;
        object-fit: contain !important;
      }
    }
  }
`
 
const AllianceCard = ({ data }) => {
  const logo = get(data, 'logo');
  const logoTitle = get(logo, 'title');
  const gatsbyImageData = get(logo, 'gatsbyImageData');
  const name = get(data, 'name');
  const text = get(data, 'text');
  const description = get(text, 'description');
  const url = get(data, 'url');
  return (
    <Wrap>
      <PlusWrap>+</PlusWrap>
      <LogoWrap name={name}>
        <WmkLink to={url} target="blank">
          <GatsbyImage image={gatsbyImageData} alt={logoTitle} />
        </WmkLink>
      </LogoWrap>
      <Type.H3 style={{margin: '1rem 0', fontSize: '1.25rem', fontWeight: 500}}>{name}</Type.H3>
      <Type.P style={{margin: '0 0 1rem', fontSize: '.9rem'}}>{description}</Type.P>
      <DipraButton
        link={{ to: url, target: "_blank", text: "LEARN MORE" }}
        rounded
        color={colors.blue.hex}
        textColor={colors.white.hex}
        fontSize=".9rem"
        hoverColor={colors.grayBlue.hex}
        innerPadding=".5rem 2rem"
      />
    </Wrap>
  )
}
 
export default AllianceCard