import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { BREAKPOINT_MEDIUM_MAX, Type } from "./Typography";
import { colors } from "../../vars/palette";
import { Container, Row, Col } from "react-bootstrap";
import DipraButton from "./DipraButton";
import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";
import { WmkLink } from "wmk-link";
import { FaAngleRight } from "react-icons/fa";

const StyledRollover = styled.div`
  position: relative;
  height: 250px;
  width: 100%;
  overflow: hidden;
  border-bottom: 3px solid ${colors.green.hex};

  .featured-image-container {
    clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: left 1s cubic-bezier(0, 0, 1, 1);
    width: 118%;
    z-index: 3;

    img {
      width: 100%;
    }

    .blend-color {
      background-color: ${colors.blue.hex};
      height: 100%;
      left: 0;
      mix-blend-mode: color;
      opacity: 1;
      position: absolute;
      width: 100%;
      z-index: 1;
    }

    .icon-container {
      font-size: 40px;
      z-index: 1;

      svg {
        color: ${colors.green.hex};
      }
    }
  }

  .to-left {
    left: -118%;
  }

  .secondary-container {
    img {
      z-index: 1;
      clip-path: polygon(0 0, 100% 0, 60% 100%, 0% 100%);
    }
  }

  .secondary-col {
    padding: 0 !important;
    height: 100%;
  }

  .content-col {
    display: flex;
    align-items: center;
    z-index: 1;
    height: 100%;
    .rollover-content-container {
      padding-right: 10%;
      padding-left: 10%;
      .supporting-text {
        margin: 0 0 19px;
        font-size: 15px;
      }
    }
  }
  @media only screen and (max-width: ${BREAKPOINT_MEDIUM_MAX}) {
    height: 150px;
  }
`;

/**
 * @component - Renders 'rollover reveal' landing page navigation section on
 * medium breakpoint and above. On mobile, no rollover happens and the component
 * acts as a button/link to url in HyperLink (button) prop
 * @param {Img} featuredImage - background before reveal
 * @param {Img} secondaryImage - image after reveal
 * @param {string} title
 * @param {string} headline
 * @param {string} text - short copy revealed on hover
 * @param {HyperLink} button
 * @returns {JSX}
 */
export const RolloverBlock = ({
  button,
  featuredImage,
  secondaryImage,
  text,
  title,
  headline,
  style
}) => {
  const [featuredVisible, setVisible] = useState(true);
  const [width, setWidth] = useState(null);

  useEffect(() => {
    const resizeEvent = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", resizeEvent);

    if (!width) {
      resizeEvent();
    }

    return () => {
      window.removeEventListener("resize", resizeEvent);
    };
  }, [width]);

  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

  const { featuredGatsby, featuredAlt } = {
    featuredGatsby: featuredImage.gatsby,
    featuredAlt: featuredImage.alt
  };
  const { secondaryGatsby, secondaryAlt } = {
    secondaryGatsby: secondaryImage.gatsby,
    secondaryAlt: secondaryImage.alt
  };

  const isBreakpointSmall = width < 769;
  const { to } = button || {};

  const DesktopFeatImgContJSX = (
    <div
      className={`featured-image-container ${
        featuredVisible ? "" : "to-left"
      }`}>
      <div className="blend-color" />
      {featuredGatsby ? (
        <GatsbyImage
          image={featuredGatsby}
          alt={featuredAlt}
          style={{ height: "250px", zIndex: 1 }}
        />
      ) : null}
      <div>
        <Type.RolloverMain>{headline ? headline : title}</Type.RolloverMain>
      </div>
    </div>
  );
  const MobileFeatImgContJSX = (
    <WmkLink to={to}>
      <div
        className={`featured-image-container ${
          featuredVisible ? "" : "to-left"
        }`}>
        <div className="blend-color" />
        {featuredGatsby ? (
          <GatsbyImage
            image={featuredGatsby}
            alt={featuredAlt}
            style={{ height: "150px" }}
          />
        ) : null}
        <div>
          <Type.RolloverMain>
            {headline ? headline : title}
            <div className="icon-container">
              <FaAngleRight />
            </div>
          </Type.RolloverMain>
        </div>
      </div>
    </WmkLink>
  );
  return (
    <StyledRollover
      onMouseOver={() => {
        !isBreakpointSmall && setVisible(false);
      }}
      onMouseLeave={() => {
        setVisible(true);
      }}
      style={style ? style : {}}>
      {!isBreakpointSmall ? DesktopFeatImgContJSX : MobileFeatImgContJSX}
      <Container fluid className="secondary-col">
        <Row className="g-0" style={{ height: "100%" }}>
          <Col sm={5} offset={0}>
            <div className="secondary-container">
              {secondaryGatsby ? (
                <GatsbyImage
                  alt={secondaryAlt}
                  image={secondaryGatsby}
                  style={{ height: "250px" }}
                />
              ) : null}
            </div>
          </Col>
          <Col className="content-col">
            <div className="rollover-content-container">
              <Type.RolloverHeadline>
                {headline ? headline : title}
              </Type.RolloverHeadline>
              <div className="supporting-text">
                <ResponsiveEllipsis
                  basedOn="letters"
                  ellipsis="..."
                  maxLine="4"
                  text={text}
                  trimRight
                />
              </div>
              <div className="button-container">
                <DipraButton
                  color={colors.darkBlue.hex}
                  fontSize="15px"
                  innerPadding=".75rem 4rem"
                  link={button}
                  textColor={colors.white.hex}
                  style={{
                    textTransform: "uppercase",
                    fontWeight: 500,
                    letterSpacing: ".063rem"
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </StyledRollover>
  );
};
