import * as React from "react";
import styled from "styled-components";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { colors } from "../../../vars/palette";
import { useEffect, useState, useRef } from "react";
// import { AltStyledForm } from "../../../components/ui/Forms";
import HubspotForm from "react-hubspot-form";

const Wrap = styled.div`
  .row {
    margin: 1vh 0;
  }
  padding-bottom: 3rem;
  h2,
  p {
    padding: 0 15vw;
    margin-bottom: 1rem;
    text-align: center;
    &:not(p) {
      color: ${colors.blue.hex};
    }
  }
  .hidden {
    opacity: 0 !important;
    z-index: -1;
  }
  input,
  select,
  textarea {
    background-color: ${colors.skyBlue.hex};
  }
  button {
    background-color: ${colors.darkBlue.hex};
    &:hover {
      background-color: ${colors.blue.hex};
    }
  }
  .hubspot-form-container {
    input,
    select,
    textarea {
      border-radius: 0.375rem;
      padding: 0.375rem 0.75rem;
      border: 1px solid #ced4da;
    }
    label {
      margin-bottom: 0.5rem;
    }
    select {
      padding: 0.45rem 2.25rem 0.45rem 0.75rem;
    }
    fieldset {
      margin: 1vh 0;
    }
    .hs-form-field {
      padding: 0 calc(1.5rem * 0.5);
    }

    .hs-form-required {
      color: red;
    }

    .form-columns-1 {
      .input {
        margin-right: 0px;
      }
    }
    .form-columns-2 {
      .input {
        margin-right: 0px;
      }
    }
    .form-columns-3 {
      .input {
        margin-right: 0px;
      }
    }
    div > form > .form-columns-3 > .hs-form-field {
      width: calc(100% / 3);
    }
    .input {
      input {
        width: 100%;
      }
      select {
        width: 100%;
      }
      .hs-input {
        width: 100%;
      }
      #e_newsletters_subscription-2713e0ce-cff4-4c30-8192-f64c2125d437 {
        width: auto;
      }
    }
    .hs-form-2713e0ce-cff4-4c30-8192-f64c2125d437_3a6312c7-e235-4a90-93aa-e3963a4037cf,
    fieldset {
      max-width: 100%;
    }

    .hs-form-booleancheckbox {
      list-style-type: none;
      input {
        margin-left: -1.95rem;
      }
      span {
        padding-left: 18px;
      }
      &::marker {
        content: "";
      }
    }
    .actions {
      display: flex;
      justify-content: center;
      margin-top: 2vh;
      input {
        color: ${colors.white.hex};
        border-radius: 0.375rem;
        background-color: ${colors.darkBlue.hex};
        transition: all 0.3s ease;
        &:hover {
          background-color: ${colors.blue.hex};
          transition: all 0.3s ease;
        }
      }
    }
  }
  @media only screen and (max-width: 400px) {
    .hubspot-form-container {
      div > form > .form-columns-1 > .hs-form-field {
        .hs-input {
          width: 100%;
        }
        .hs-form-booleancheckbox-display {
          .hs-input {
            width: auto;
          }
        }
      }
      div > form > .form-columns-2 > .hs-form-field {
        float: left !important;
        width: 50% !important;
        .hs-input {
          width: 100% !important;
        }
      }
      div > form > .form-columns-3 > .hs-form-field {
        float: left !important;
        width: calc(100% / 3) !important;
        .hs-input {
          width: 100% !important;
        }
      }
    }
  }
  @media (max-width: 400px),
    (min-device-width: 320px) and (max-device-width: 480px) {
    .hubspot-form-container {
      div > form > .form-columns-1 > .hs-form-field {
        .hs-input {
          width: 100%;
        }
        .hs-form-booleancheckbox-display {
          .hs-input {
            width: auto;
          }
        }
      }
      div > form > .form-columns-2 > .hs-form-field {
        float: left !important;
        width: 50% !important;
        .hs-input {
          width: 100% !important;
        }
      }
      div > form > .form-columns-3 > .hs-form-field {
        float: left !important;
        width: calc(100% / 3) !important;
        .hs-input {
          width: 100% !important;
        }
      }
    }
  }
`;

const RequiredField = () => <span style={{ color: "red" }}>*</span>;

const statesAndProvinces: [string, string, number[]][] = [
  ["Alabama", "AL", [2]],
  ["Alaska", "AK", [3]],
  ["Arizona", "AZ", [4]],
  ["Arkansas", "AR", [2]],
  ["California", "CA", [3, 4]],
  ["Colorado", "CO", [3]],
  ["Connecticut", "CT", [1]],
  ["Delaware", "DE", [1]],
  ["District of Columbia", "DC", [2]],
  ["Florida", "FL", [2]],
  ["Georgia", "GA", [2]],
  ["Hawaii", "HI", [4]],
  ["Idaho", "ID", [3]],
  ["Illinois", "IL", [1]],
  ["Indiana", "IN", [1]],
  ["Iowa", "IA", [1]],
  ["Kansas", "KS", [3]],
  ["Kentucky", "KY", [2]],
  ["Louisiana", "LA", [2]],
  ["Maine", "ME", [1]],
  ["Maryland", "MD", [1]],
  ["Massachusetts", "MA", [1]],
  ["Michigan", "MI", [1]],
  ["Minnesota", "MN", [1]],
  ["Mississippi", "MS", [2]],
  ["Missouri", "MO", [1, 2]],
  ["Montana", "MT", [3]],
  ["Nebraska", "NE", [3]],
  ["Nevada", "NV", [3, 4]],
  ["New Hampshire", "NH", [1]],
  ["New Jersey", "NJ", [1]],
  ["New Mexico", "NM", [4]],
  ["New York", "NY", [1]],
  ["North Carolina", "NC", [2]],
  ["North Dakota", "ND", [3]],
  ["Ohio", "OH", [1]],
  ["Oklahoma", "OK", [2]],
  ["Oregon", "OR", [3]],
  ["Pennsylvania", "PA", [1]],
  ["Rhode Island", "RI", [1]],
  ["South Carolina", "SC", [2]],
  ["South Dakota", "SD", [3]],
  ["Tennessee", "TN", [2]],
  ["Texas", "TX", [2, 4]],
  ["Utah", "UT", [3, 4]],
  ["Vermont", "VT", [1]],
  ["Virginia", "VA", [2]],
  ["Washington", "WA", [3]],
  ["West Virginia", "WV", [2]],
  ["Wisconsin", "WI", [1]],
  ["Wyoming", "WY", [3]],
  ["Alberta", "AB", [5]],
  ["British Columbia", "BC", [5]],
  ["Manitoba", "MB", [5]],
  ["New Brunswick", "NB", [5]],
  ["Newfoundland and Labrador", "NL", [5]],
  ["Northwest Territories", "NT", [5]],
  ["Nova Scotia", "NS", [5]],
  ["Nunavut", "NU", [5]],
  ["Ontario", "ON", [5]],
  ["Prince Edward Island", "PE", [5]],
  ["Quebec", "QC", [5]],
  ["Saskatchewan", "SK", [5]],
  ["Yukon", "YT", [5]]
];

const AskAnEngineerForm = ({ isContactPage }: { isContactPage?: boolean }) => {
  const [reZone, setReZone] = useState<number[]>([]);
  const [form, setForm] = useState<HTMLFormElement>();
  const [hiddenForm, setHiddenForm] = useState<HTMLDivElement>();

  const formRef = useRef<HTMLFormElement>();
  const hiddenFormRef = useRef<HTMLDivElement>();

  const handleSelect: React.FormEventHandler<HTMLSelectElement> = (e) => {
    const value = e.currentTarget.value;
    const select = form.querySelector("#stateProvince");
    const option = select.querySelector(`[value=${value}]`);
    const zone = option.getAttribute("data-zone").split(",");
    setReZone(zone.map((z) => parseInt(z)));
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const formData = new FormData(form);
    const dataHash = {
      firstname: String(formData.get("firstName")),
      lastname: String(formData.get("lastName")),
      jobtitle: String(formData.get(`title`)),
      company: String(formData.get("company")),
      dipra_industries: String(formData.get("industry")),
      address: String(formData.get("address")),
      city: String(formData.get("city")),
      state_province: String(formData.get("stateProvince")),
      email: String(formData.get("email")),
      phone: String(formData.get("phone")),
      subject: String(formData.get("subject")),
      message: String(formData.get("message")),
      e_newsletters_subscription: formData.get("subscribe") ? true : false,
      "Zone 1": reZone.find((z) => z === 1) ? true : false,
      "Zone 2": reZone.find((z) => z === 2) ? true : false,
      "Zone 3": reZone.find((z) => z === 3) ? true : false,
      "Zone 4": reZone.find((z) => z === 4) ? true : false,
      "Zone 5": reZone.find((z) => z === 5) ? true : false
    };
    const keys = Object.keys(dataHash);
    const zones = keys.slice(keys.length - 5, keys.length);
    const inputs = keys.slice(0, keys.length - 5);
    inputs.forEach((i) => {
      if (i in dataHash) {
        const input = hiddenForm.querySelector<
          HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >(`[name=${i}]`);

        input.value = dataHash[i];
      }
    });
    // zones.forEach((z) => {
    //   const zone = hiddenForm.querySelector<HTMLInputElement>(`[value="${z}"]`);
    //   if (zone) {
    //     zone.checked = dataHash[z];
    //   }
    // });
    hiddenForm.querySelector("form").submit();
    // setTimeout(
    //   () =>
    //     window.location.replace(`https://dipra.org/thank-you-ask-an-engineer`),
    //   500
    // );
  };
  useEffect(() => {
    const target = formRef.current;
    const hidden = hiddenFormRef.current;
    setHiddenForm(hidden);
    setForm(target);
  }, []);
  return (
    <Container>
      <Wrap id="ask-engineer-form">
        {isContactPage ? (
          <>
            <h2>Ask An Engineer</h2>
            <p>
              DIPRA's engineers are glad to answer your questions about the use
              and specification of Ductile Iron Pipe. Please submit your
              question using the following form. Your DIPRA engineer will
              respond shortly.
            </p>
          </>
        ) : (
          <></>
        )}
        {/* <Form onSubmit={handleSubmit} ref={formRef}>
          <Row>
            <Col>
              <Form.Group controlId="firstName">
                <Form.Label>
                  First Name <RequiredField />
                </Form.Label>
                <Form.Control required name="firstName" />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="lasttName">
                <Form.Label>
                  Last Name <RequiredField />
                </Form.Label>
                <Form.Control required name="lastName" />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="title">
                <Form.Label>Title</Form.Label>
                <Form.Control name="title" />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="company">
                <Form.Label>
                  Company or Organization <RequiredField />
                </Form.Label>
                <Form.Control required name="company" />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="industry">
                <Form.Label>
                  Industry <RequiredField />
                </Form.Label>
                <Form.Select required defaultValue="" name="industry">
                  <option disabled value="">
                    Select One
                  </option>
                  <option value="Consulting Engineer">
                    Consulting Engineer
                  </option>
                  <option value="Public Utility">Public Utility</option>
                  <option value="Private Utility">Private Utility</option>
                  <option value="Federal Government">Federal Government</option>
                  <option value="Local Government">Local Government</option>
                  <option value="Other">Other</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="address">
                <Form.Label>Address</Form.Label>
                <Form.Control name="address" />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="city">
                <Form.Label>
                  City <RequiredField />
                </Form.Label>
                <Form.Control required name="city" />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="stateProvince">
                <Form.Label>
                  State / Province <RequiredField />
                </Form.Label>
                <Form.Select
                  required
                  onChange={handleSelect}
                  defaultValue=""
                  name="stateProvince">
                  <option value="" disabled>
                    Select
                  </option>
                  {statesAndProvinces.map((state) => {
                    return (
                      <option
                        value={state[0]}
                        key={state[1]}
                        data-zone={state[2].join()}>
                        {state[0]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="postalCode">
                <Form.Label>ZIP / Postal Code</Form.Label>
                <Form.Control name="postalCode" />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="email">
                <Form.Label>
                  Email <RequiredField />
                </Form.Label>
                <Form.Control type="email" required name="email" />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="phone">
                <Form.Label>
                  Phone <RequiredField />
                </Form.Label>
                <Form.Control type="phone" required name="phone" />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="subject">
                <Form.Label>
                  Subject <RequiredField />
                </Form.Label>
                <Form.Control required name="subject" />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="message">
                <Form.Label>
                  Message <RequiredField />
                </Form.Label>
                <Form.Control required as="textarea" name="message" />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="subscribe">
                <Form.Check
                  type="checkbox"
                  label="Check this box to subscribe to DIPRA's e-newsletters for topical commentary, resources, services, and events."
                  defaultChecked
                  name="subscribe"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row style={{ width: `100%` }}>
            <Col
              style={{
                display: `flex`,
                justifyContent: `center`,
                marginTop: `2vh`
              }}>
              <Button type="submit">Submit</Button>
            </Col>
          </Row>
        </Form> */}
        <div ref={hiddenFormRef} className="hubspot-form-container">
          <HubspotForm
            portalId="21124615"
            formId="2713e0ce-cff4-4c30-8192-f64c2125d437"
            loading={<div>Loading...</div>}
          />
        </div>
      </Wrap>
    </Container>
  );
};

export default AskAnEngineerForm;

export const parseParams = (search: string) => {
  return JSON.parse(
    '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
    function (key, value) {
      return key === "" ? value : decodeURIComponent(value);
    }
  );
};
