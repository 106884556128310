import { Img } from "wmk-image";
import { AssetFile, BrandAssetQuery } from "../fragments/NodeBrandAssetFields";

export class BrandAsset {
  brandAssetId: string;
  image: Img;
  title: string;
  asset1: AssetFile;
  asset2: AssetFile;
  asset3: AssetFile;
  constructor(node: BrandAssetQuery) {
    const _node = { ...node };
    this.image = new Img(_node.image);
    this.title = _node.title;
    this.asset1 = _node.asset1;
    this.asset2 = _node.asset2;
    this.asset3 = _node.asset3;
  }
}

export class BrandAssets {
  list: BrandAsset[];
  length: number;
  constructor(edges: { node: BrandAssetQuery }[]) {
    const brandAsset = Array.isArray(edges)
      ? edges.map((e) => new BrandAsset(e.node))
      : [];
    this.list = brandAsset;
    this.length = brandAsset.length;
  }
}
