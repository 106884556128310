import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { WmkLink } from "wmk-link";
import { AiOutlineDownload } from "react-icons/ai";
import styled from "styled-components";
import { Img, WmkImage } from "wmk-image";
import { Type } from "./Typography";
import { AssetFile } from "../../fragments/NodeBrandAssetFields";
import { colors } from "../../vars/palette";

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  padding: 2rem !important;
  box-shadow: 0px 2px 8px ${colors.darkGray.hex};
  h3 {
    text-align: left;
    margin: 1rem 0 0.5rem;
  }
  a {
    color: ${colors.green.hex};
  }
  // img {
  //   max-height: 25vh;
  // }
  // @media only screen and (min-width: 768px) {
  //   height: 50vh;
  // }
  // @media only screen and (min-height: 200px) {
  //   img {
  //     max-height: 90vh;
  //   }
  //   height: 150vh;
  // }
  // @media only screen and (min-height: 400px) {
  //   img {
  //     max-height: 50vh;
  //   }
  //   height: 100vh;
  // }
  // @media only screen and (min-height: 500px) {
  //   img {
  //     max-height: 40vh;
  //   }
  //   height: 75vh;
  // }
  // @media only screen and (min-height: 500px) and (max-width: 768px) {
  //   img {
  //     max-height: 45vh;
  //   }
  //   height: 90vh;
  // }
  // @media only screen and (min-height: 600px) {
  //   img {
  //     max-height: 30vh;
  //   }
  //   height: 70vh;
  // }
`;

const BrandAssetBlock = ({
  image,
  title,
  asset1,
  asset2,
  asset3
}: {
  image: Img;
  title: string;
  asset1: AssetFile;
  asset2: AssetFile;
  asset3: AssetFile;
}) => {
  const getTypeText = (asset: AssetFile) => {
    const type = asset.file.contentType;
    if (type.includes("pdf")) {
      return "PDF";
    } else if (type.includes("pdf")) {
      return "PDF";
    } else if (type.includes("jpeg")) {
      return "300 DPI JPG";
    } else if (type.includes("png")) {
      return "300 DPI PNG";
    } else if (type.includes("eps")) {
      return "EPS";
    } else if (type.includes(".document")) {
      return "Word Doc";
    }
  };
  const displayAsset = (asset: AssetFile) => {
    const typeText = getTypeText(asset);
    return (
      <WmkLink target={asset.file.url} to={asset.file.url}>
        <Type.P
          style={{
            color: colors.green.hex
          }}>
          Download {typeText}
        </Type.P>
      </WmkLink>
    );
  };

  return (
    <>
      <Container fluid style={{ margin: "2vh 0" }}>
        <Row>
          <StyledCol>
            <WmkImage image={image} />
            <Type.IconCardHeadline>{title}</Type.IconCardHeadline>
            {asset1 && displayAsset(asset1)}
            {asset2 && displayAsset(asset2)}
            {asset3 && displayAsset(asset3)}
          </StyledCol>
        </Row>
      </Container>
    </>
  );
};
export default BrandAssetBlock;
