import * as React from "react";
import { Card, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../vars/palette";
import { Type } from "./Typography";
import { WmkLink } from "wmk-link";
import { WmkImage } from "wmk-image";
import { StaffAsset } from "../../classes/StaffAsset";
import { AiFillFilePdf, AiFillFileWord } from "react-icons/ai";

const Wrap = styled.div`
  max-width: 18rem;
  padding: 3vh 0;
  .gatsby-image-wrapper {
    width: 162px;
  }
  a {
    color: ${colors.lightGreen.hex};
    text-decoration: underline;
  }
  a:hover {
    color: ${colors.blue.hex};
  }
  a > div > p {
    color: ${colors.lightGreen.hex};
  }
  a > div > p:hover {
    color: ${colors.blue.hex};
  }
  p {
    margin-bottom: 0;
  }
  h6 {
    font-size: 2.5rem;
  }
  @media only screen and (max-width: 575px) {
    margin: 0 10vw;
  }
`;

const StaffAssetBlock = ({
  image,
  jobTitle,
  name,
  biographyPdf,
  biographyWordDoc,
  headshot150Dpi,
  headshot300Dpi
}: StaffAsset) => {
  return (
    <Wrap>
      <WmkImage image={image} />
      <Type.P
        style={{
          color: colors.lightGreen.hex,
          textTransform: "uppercase",
          fontSize: ".9rem",
          fontWeight: "bold",
          margin: "1rem 0 0"
        }}>
        {jobTitle}
      </Type.P>
      <Type.H6 style={{ textTransform: "none", marginTop: `.5rem` }}>
        {name}
      </Type.H6>
      <Type.P>Biography:</Type.P>
      <WmkLink
        to={biographyPdf.file.url}
        target={biographyPdf.file.url}
        style={{ display: "inline" }}>
        <h6 style={{ display: "inline" }}>
          <AiFillFilePdf />
        </h6>
      </WmkLink>
      {biographyWordDoc?.file?.url && <WmkLink
        to={biographyWordDoc?.file?.url}
        target={biographyWordDoc?.file?.url}
        style={{ display: "inline" }}>
        <h6 style={{ display: "inline" }}>
          <AiFillFileWord />
        </h6>
      </WmkLink>}
      <Type.P style={{ marginTop: "1rem" }}>Headshot:</Type.P>
      {headshot150Dpi && (
        <WmkLink to={headshot150Dpi.file.url} target={headshot150Dpi.file.url}>
          <Type.P>Download Headshot JPG</Type.P>
        </WmkLink>
      )}
      {headshot300Dpi && (
        <WmkLink to={headshot300Dpi.file.url} target={headshot300Dpi.file.url}>
          <Type.P>300 DPI</Type.P>
        </WmkLink>
      )}
    </Wrap>
  );
};

export default StaffAssetBlock;
