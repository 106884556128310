import * as React from "react";
import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import get from "lodash/get";
import { Type } from "../../../components/ui/Typography";
import { WmkLink } from "wmk-link";
import { colors } from "../../../vars/palette";
import { WmkImage } from "wmk-image";
import { StaffMember } from "../../../classes/Staff";

const Wrap = styled.div<{ titleSize: number; nameSize: number }>`
  ${({ titleSize }) =>
    titleSize
      ? `.title-div {
        min-height: ${titleSize}px;
      }`
      : ``}
  ${({ nameSize }) =>
    nameSize
      ? `.name-div {
        min-height: ${nameSize}px;
      }`
      : ``}
  max-width: 18rem;
  padding: 3vh 0;
  .gatsby-image-wrapper {
    width: 162px;
  }
  a {
    color: ${colors.lightGreen.hex};
    text-decoration: underline;
  }
  p {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 575px) {
    max-width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title-div {
      text-align: center;
    }
    .name-div {
      text-align: center;
    }
  }
  @media only screen and (max-width: 386px) {
    .title-div {
      min-height: 0;
    }
    .name-div {
      min-height: 0;
    }
  }
`;
const AddressWrap = styled.div<{ addressSize: number }>`
  ${({ addressSize }) => (addressSize ? `min-height: ${addressSize}px;` : ``)}
  padding: 0.5rem 0;
  p {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 575px) {
    text-align: center;
  }
  @media only screen and (max-width: 386px) {
    min-height: 0;
  }
`;

const StaffCard = ({
  data,
  titleSize,
  nameSize,
  addressSize,
  setTitleSize,
  setNameSize,
  setAddressSize
}: {
  data: StaffMember;
  titleSize: number;
  nameSize: number;
  addressSize: number;
  setTitleSize: Function;
  setNameSize: Function;
  setAddressSize: Function;
}) => {
  const titleRef = useRef();
  const nameRef = useRef();
  const addressRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      const current = titleRef.current;
      if (current) {
        const rect = current.getBoundingClientRect();
        setTitleSize(rect.height);
      }
    };
    // handleResize();
    setTimeout(() => {
      handleResize();
    }, 100);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const current = nameRef.current;
      if (current) {
        const rect = current.getBoundingClientRect();
        setNameSize(rect.height);
      }
    };
    // handleResize();
    setTimeout(() => {
      handleResize();
    }, 100);
  }, []);
  
  useEffect(() => {
    const handleResize = () => {
      const current = addressRef.current;
      if (current) {
        const rect = current.getBoundingClientRect();
        setAddressSize(rect.height);
      }
    };
    // handleResize();
    setTimeout(() => {
      handleResize();
    }, 100);
  }, []);

  const name = data.name;
  const jobTitle = data.jobTitle;
  const image = data.image;
  const addressLinesArr = data.addressLinesArr;
  const contactInfo = data.contactInfo.list;
  const bio = data.bio;
  return (
    <Wrap titleSize={titleSize} nameSize={nameSize}>
      <WmkImage image={image} />
      <div ref={titleRef} className="title-div">
        <Type.P
          style={{
            color: colors.lightGreen.hex,
            textTransform: "uppercase",
            fontSize: ".9rem",
            fontWeight: "bold",
            margin: "1rem 0 0"
          }}>
          {jobTitle}
        </Type.P>
      </div>
      <div ref={nameRef} className="name-div">
        <Type.H6
          style={{
            textTransform: "none",
            marginTop: `.5rem`,
            marginBottom: 0
          }}>
          {name}
        </Type.H6>
      </div>
      <AddressWrap ref={addressRef} addressSize={addressSize}>
        {addressLinesArr.map((line, i) => (
          <Type.P key={line + i} style={{ marginBottom: `0px !important` }}>
            {line}
          </Type.P>
        ))}
      </AddressWrap>
      {contactInfo.map((con, j) => {
        const type = con.type;
        const text = con.text;
        return (
          <Type.P key={text + j}>
            <WmkLink
              target={con.target}
              to={con.to}
              mailto={type === "mailto" ? true : undefined}
              tel={type === "tel" ? true : undefined}>
              {text}
            </WmkLink>
          </Type.P>
        );
      })}
      {bio && (
        <Type.P>
          <WmkLink target="_blank" to={bio}>
            Read Bio
          </WmkLink>
        </Type.P>
      )}
    </Wrap>
  );
};

export default StaffCard;
