import { WmkLink } from "wmk-link";
import * as React from "react";
/**
 * Handles contentful contact info
 */
export interface ContactInfoData {
  to: string;
  text: string;
  target?: "New Window" | "Same Window";
  type?: "tel" | "mailto" | "url";
  label: "url" | "phone" | "fax" | "cell" | "mobile" | "office";
}

export interface ContactInfoNode {
  value: string;
  type: "phone" | "fax" | "cell" | "mobile" | "office" | "url";
}

export class ContactInfo {
  list: ContactInfoData[];
  length: number;
  url?: string;
  constructor(node: ContactInfoNode[]) {
    let url = "";
    const info = node.map((data: ContactInfoNode) => {
      const { value } = data;
      const _type =
        typeof data.type === "string" ? data.type.toLowerCase() : "";
      const target: "New Window" | "Same Window" =
        _type === "url" ? "New Window" : "Same Window";
      const type: "tel" | "mailto" | "url" = _type.match(
        /phone|fax|cell|mobile|office/
      )
        ? "tel"
        : _type === "email"
        ? "mailto"
        : "url"; //_type;
      if (url === "" && type === "url") {
        url = value;
      }

      return {
        to: value,
        text: value,
        target,
        type,
        label: data.type
      };
    });

    this.list = info;
    this.length = info.length;
    this.url = url;
  }
  /**
   * Returns JSX of linked contact info
   */
  jsx(_key = "") {
    return this.list.map((contact, i) => {
      const { to, text, target, type } = contact;
      const key = _key + text + type + i;
      return type === "mailto" ? (
        <WmkLink mailto key={key}>
          {to}
        </WmkLink>
      ) : type === "tel" ? (
        <WmkLink tel key={key}>
          {to}
        </WmkLink>
      ) : (
        <WmkLink
          to={to}
          target={
            target === "New Window"
              ? "blank"
              : target === "Same Window"
              ? "self"
              : undefined
          }
          key={key}>
          {text}
        </WmkLink>
      );
    });
  }
}
