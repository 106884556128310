import * as React from 'react';
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby';
import AllianceCard from './AllianceCard';
 
const Wrap = styled.div`
  * {
  }
  padding: 3rem 0;
`
 
const AlliancesContent = props => {
  const allianceArr = useStaticQuery(query).alliances.edges.map(p=>p.node)
  return (
    <Wrap>
      <Container>
        {allianceArr.map((alliance, i) => (
          <Row key={alliance.title + i.toString()}>
            <Col>
              <AllianceCard data={alliance} />
            </Col>
          </Row>          
        ) )}
      </Container>
    </Wrap>
  )
}
 
export default AlliancesContent

const query = graphql`
  query {
    alliances: allContentfulAlliance(sort: {order: ASC, fields: title}) {
      edges {
        node {
          ...NodeAllianceFields
        }
      }
    }
  }
`