import * as React from "react";
import { RolloverBlock } from "./RolloverBlock";

const NullComponent = () => <></>;

const RolloverSection = ({ rollovers }) => {
  const Rollovers = () => (
    <>
      {Array.isArray(rollovers)
        ? rollovers.map((section, i) => {
            const {
              featuredImage,
              secondaryImage,
              title,
              headline,
              text,
              button
            } = section;
            return (
              <RolloverBlock
                featuredImage={featuredImage}
                secondaryImage={secondaryImage}
                title={title}
                headline={headline}
                text={text}
                button={button}
                key={title + i}
                style={i === 0 ? { marginTop: "2rem" } : {}}
              />
            );
          })
        : NullComponent}
    </>
  );
  return Array.isArray(rollovers) ? <Rollovers /> : <NullComponent />;
};

export default RolloverSection;
