import { RegionalEngineerFields } from "../fragments/NodeRegionalEngineerFields";
import { getHashTable } from "./Page";
import { StaffMember } from "./Staff";
/**
 * @class
 * Object representing DIPRA engineers
 */
export class RegionalEngineer extends StaffMember {
  region: string;
  addressArr: string[];
  constructor(node: RegionalEngineerFields) {
    super(node);
    const region = node.region;
    const addressArr = node.full.address.split(`\n`);
    this.region = region;
    this.addressArr = addressArr;
    const bio = node.bio && node.bio.file.url;
    this.bio = bio;
  }
}

export class RegionalEngineers {
  /**
   * collection of NodeRegionalEngineer results
   */
  list: RegionalEngineer[];
  length: number;
  hash: { [key: string]: RegionalEngineer };
  constructor(edges: { node: RegionalEngineerFields }[]) {
    const staff = Array.isArray(edges)
      ? edges.map((e) => new RegionalEngineer(e.node))
      : [];
    this.list = staff;
    this.length = staff.length;
    this.hash = getHashTable(staff, "region");
  }
}
