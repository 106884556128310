import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFilePdf } from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components'
import { ContentfulImageQuery, Img, WmkImage } from 'wmk-image';
import { Type } from './Typography';


const AdWrap = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 4rem;
  .gatsby-image-wrapper, .gatsby-image-wrapper * {
    // max-width: 16rem;
  }
  span {
    margin-top: 1rem;
    display: flex;
    a {
      text-decoration: none;
    }
    svg {
      margin-left: .5rem;
    }
  }
`
const ImgWrap = styled.div`
  * {
    max-height: 15rem;
  }

`
const Ad = ({ ad }: { ad: { blockId: string; image?: ContentfulImageQuery; copy?: { text: string; } } }) => {
  const title = ad.blockId;
  const image = new Img(ad?.image);
  const pdfLinkMarkdown = ad?.copy?.text.replace("!", "");
  return (
    <AdWrap xs={12} sm={6}>
      <ImgWrap>
        <WmkImage image={image} />
      </ImgWrap>
      <Type.H6 style={{textTransform: 'none', fontSize: '1.5rem', marginTop: '1rem'}}>{title}</Type.H6>
      {pdfLinkMarkdown && 
      <span>
        <ReactMarkdown>{pdfLinkMarkdown}</ReactMarkdown>
        <FaFilePdf size="1.5rem" color="red" />
      </span>}
    </AdWrap>
  )
}



const AdBank = ({ content }) => {
  const ads = content.references;
  return (
    <Container style={{position: 'relative'}}>
      <Row>
        {ads 
          ? ads.map((ad, i) => <Ad ad={ad} />)
          : <p>No ads found.</p>
        }
      </Row>
    </Container>
  )
}
 
export default AdBank