import get from "lodash/get";
import { Img } from "wmk-image";
import { MemberCompanyFields } from "../fragments/NodeMemberCompanyFields";
import { ContactInfo } from "./ContactInfo";
import { HyperLink } from "./HyperLink";

/**
 * @class
 * Object that represents member company
 */
export class MemberCompany {
  /**
   */
  name: string;
  link?: HyperLink;
  logo?: Img;
  contact?: ContactInfo;
  address: string[];
  constructor(node: MemberCompanyFields) {
    const name = node.title;
    const contact = node.contactInfo
      ? new ContactInfo(node.contactInfo)
      : undefined;
    const addy = node.full?.address;
    this.name = name;
    this.link = contact.url
      ? new HyperLink({
          to: contact.url,
          target: "New Window",
          name
        })
      : undefined;
    this.logo = node.logo ? new Img(node.logo) : undefined;
    this.contact = contact;
    this.address = addy ? addy.split("\n") : [];
  }
}

export class MemberCompanies {
  /**
   *
   */
  list: MemberCompany[];
  length: number;
  constructor(edges: { node: MemberCompanyFields }[]) {
    const staff = Array.isArray(edges)
      ? edges.map((e) => new MemberCompany(e.node))
      : [];
    this.list = staff;
    this.length = staff.length;
  }
}
