import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
// import StaffCard from "./StaffCard";
// import { Staff } from "../../../classes/Staff";
import get from "lodash/get";
// import { Type } from "../../../components/ui/Typography";
// import { StaffFields } from "../../../fragments/NodeStaffFields";
import { BrandAssetQuery } from "../fragments/NodeBrandAssetFields";
import { StaffAssetQuery } from "../fragments/NodeStaffAssetFields";
import { StaffAsset, StaffAssets } from "../classes/StaffAsset";
import { Type } from "../components/ui/Typography";
import StaffAssetBlock from "../components/ui/StaffAssetBlock";
import { colors } from "../vars/palette";
import { BrandAssets } from "../classes/BrandAsset";
import BrandAssetBlock from "../components/ui/BrandAssetBlock";

const Wrap = styled.div`
  margin-bottom: 6vh;
  h1 {
    text-align: center;
  }
  .container-fluid > .row > div > img {
    padding: 3vh !important;
    @media only screen and (max-width: 768px) {
      padding: 8vh !important;
    }
  }
`;
const GrayLine = styled.div`
  @media only screen and (max-width: 768px) {
    background: ${colors.darkGray.hex};
    height: 5px;
    width: 90%;
    margin: 0 5%;
  }
`;

interface ConferencePageQuery {
  brandAsset: {
    edges: {
      node: BrandAssetQuery;
    }[];
  };
  staffAsset: {
    edges: {
      node: StaffAssetQuery;
    }[];
  };
}

const ConferenceAssetContent = () => {
  const data: ConferencePageQuery = useStaticQuery(query);
  const brandAssets = new BrandAssets(data.brandAsset.edges);
  const staff = new StaffAssets(data.staffAsset.edges);
  const groups = staff.groups;
  const sortedGroups = Object.keys(groups).sort((a, b) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  });
  return (
    <Wrap>
      <Container style={{ marginBottom: "4rem" }}>
        <Type.H2
          style={{
            margin: "6rem 0px 3rem",
            textAlign: "center",
            color: colors.blue.hex,
            fontSize: "2rem"
          }}>
          Brand Assets
        </Type.H2>
        <Row style={{ justifyContent: "center" }}>
          {brandAssets.list.map((asset, i) => {
            return (
              <Col key={asset.title + i} md={4}>
                <BrandAssetBlock
                  image={asset.image}
                  title={asset.title}
                  asset1={asset.asset1}
                  asset2={asset.asset2}
                  asset3={asset.asset3}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
      <Container>
        <GrayLine />
        <Type.H2
          style={{
            margin: "6rem 0 3rem",
            textAlign: "center",
            color: colors.blue.hex,
            fontSize: "2rem"
          }}>
          Staff Assets
        </Type.H2>
        {sortedGroups.map((group, i) => {
          const members = groups[group];
          return (
            <div key={group + i}>
              {/* <Type.H3 style={{ margin: "3rem 0 1rem" }}>{group}</Type.H3> */}
              {/* {group.match(/engineer/i) ? <Type.P>{reLeadIn}</Type.P> : null} */}
              <Row>
                {members.map((person, j) => {
                  return (
                    <Col key={person.name + j}>
                      <StaffAssetBlock
                        staffAssetId={person.staffAssetId}
                        image={person.image}
                        jobTitle={person.jobTitle}
                        name={person.name}
                        group={person.group}
                        biographyPdf={person.biographyPdf}
                        biographyWordDoc={person.biographyWordDoc}
                        headshot150Dpi={person.headshot150Dpi}
                        headshot300Dpi={person.headshot300Dpi}
                        order={person.order}
                      />
                    </Col>
                  );
                })}
              </Row>
            </div>
          );
        })}
      </Container>
    </Wrap>
  );
};

export default ConferenceAssetContent;

const query = graphql`
  query {
    brandAsset: allContentfulBlockBrandAsset(
      filter: { brandAssetId: { ne: "Schema" } }
    ) {
      edges {
        node {
          ...NodeBrandAssetFields
        }
      }
    }
    staffAsset: allContentfulBlockStaffAsset(
      filter: { staffAssetId: { ne: "Schema" } }
      sort: { order: ASC, fields: order }
    ) {
      edges {
        node {
          ...NodeStaffAssetFields
        }
      }
    }
  }
`;
