import * as React from "react";
import { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import StaffCard from "./StaffCard";
import { Staff } from "../../../classes/Staff";
import { Type } from "../../../components/ui/Typography";
import { StaffFields } from "../../../fragments/NodeStaffFields";

const Wrap = styled.div`
  margin-bottom: 6vh;
`;

const StaffContent = () => {
  const data: StaffPageQuery = useStaticQuery(query);
  const staff = new Staff(data.staff.edges);
  const reLeadIn = data.leadin.subtext.copy;
  const groups = staff.groups;

  const [largestManagementTitle, setLargestManagementTitle] =
    useState<number>(0);
  const [largestReTitle, setLargestReTitle] = useState<number>(0);
  const [largestManagementName, setLargestManagementName] = useState<number>(0);
  const [largestReName, setLargestReName] = useState<number>(0);
  const [largestManagementAddress, setLargestManagementAddress] =
    useState<number>(0);
  const [largestReAddress, setLargestReAddress] = useState<number>(0);
  const [currentTitle, setCurrentTitle] = useState<number[]>([]);
  const [currentName, setCurrentName] = useState<number[]>([]);
  const [currentAddress, setCurrentAddress] = useState<number[]>([]);
  const [cumulativeTitles, setCumulativeTitles] = useState<number[]>([]);
  const [cumulativeNames, setCumulativeNames] = useState<number[]>([]);
  const [cumulativeAddresses, setCumulativeAddresses] = useState<number[]>([]);
  // let currentTitle: number[] | undefined;
  // let currentName: number[] | undefined;
  // let currentAddress: number[] | undefined;

  // useEffect(() => {
  //   console.log("currentTitle", currentTitle);
  // }, [currentTitle]);

  useEffect(() => {
    setCumulativeTitles([...cumulativeTitles, ...currentTitle]);
  }, [currentTitle]);

  useEffect(() => {
    setCumulativeNames([...cumulativeNames, ...currentName]);
  }, [currentName]);

  useEffect(() => {
    setCumulativeAddresses([...cumulativeAddresses, ...currentAddress]);
  }, [currentAddress]);

  // useEffect(() => {
  //   console.log("cumulativeTitles", cumulativeTitles);
  // }, [cumulativeTitles]);

  const setCurrentTitleSize = (size: number) => {
    // if (!currentTitle) {
    //   currentTitle = [];
    // }
    // currentTitle.push(size);
    setCurrentTitle([...currentTitle, size]);
  };
  const setCurrentNameSize = (size: number) => {
    // if (!currentName) {
    //   currentName = [];
    // }
    // currentName.push(size);
    setCurrentName([...currentName, size]);
  };
  const setCurrentAddressSize = (size: number) => {
    // if (!currentAddress) {
    //   currentAddress = [];
    // }
    // currentAddress.push(size);
    setCurrentAddress([...currentAddress, size]);
  };

  useEffect(() => {
    setLargestManagementTitle(
      Math.max(...cumulativeTitles.slice(0, groups.Management.length))
    );
    setLargestReTitle(
      Math.max(
        ...cumulativeTitles.slice(
          groups.Management.length + 1,
          cumulativeTitles.length
        )
      )
    );
  }, [cumulativeTitles]);

  useEffect(() => {
    setLargestManagementName(
      Math.max(...cumulativeNames.slice(0, groups.Management.length))
    );
    setLargestReName(
      Math.max(
        ...cumulativeNames.slice(
          groups.Management.length + 1,
          cumulativeNames.length
        )
      )
    );
  }, [cumulativeNames]);

  useEffect(() => {
    setLargestReAddress(
      Math.max(...cumulativeAddresses.slice(0, groups.Management.length))
    );
    setLargestReAddress(
      Math.max(
        ...cumulativeAddresses.slice(
          groups.Management.length + 1,
          cumulativeAddresses.length
        )
      )
    );
  }, [cumulativeAddresses]);

  return (
    <Wrap>
      <Container>
        {Object.keys(groups).map((group, i) => {
          const members = groups[group];

          return (
            <div key={group + i}>
              <Type.H3 style={{ margin: "3rem 0 1rem" }}>{group}</Type.H3>
              {group.match(/engineer/i) ? <Type.P>{reLeadIn}</Type.P> : null}
              <Row>
                {members.map((person, j) => {
                  return (
                    <Col key={person.name + j}>
                      <StaffCard
                        data={person}
                        titleSize={
                          i === 0 ? largestManagementTitle : largestReTitle
                        }
                        nameSize={
                          i === 0 ? largestManagementName : largestReName
                        }
                        addressSize={
                          i === 0 ? largestManagementAddress : largestReAddress
                        }
                        setTitleSize={setCurrentTitleSize}
                        setNameSize={setCurrentNameSize}
                        setAddressSize={setCurrentAddressSize}
                      />
                    </Col>
                  );
                })}
              </Row>
            </div>
          );
        })}
      </Container>
    </Wrap>
  );
};

export default StaffContent;

interface StaffPageQuery {
  staff: { edges: { node: StaffFields }[] };
  leadin: { subtext: { copy: string } };
}

const query = graphql`
  query {
    staff: allContentfulStaff(sort: { order: ASC, fields: order }) {
      edges {
        node {
          ...NodeStaffFields
        }
      }
    }
    leadin: contentfulPage(title: { eq: "Contact a Regional Engineer" }) {
      subtext {
        copy: subtext
      }
    }
  }
`;
