import get from "lodash/get";
import { ContactInfo } from "./ContactInfo";
import { Img as OldImg } from "./Img";
import { Img } from "wmk-image";
import { StaffFields } from "../fragments/NodeStaffFields";

/**
 * @class
 * Object that represents staff member
 */
export class StaffMember {
  name: string;
  jobTitle: string;
  image: Img;
  address: string;
  addressLinesArr: string[];
  contactInfo?: ContactInfo;
  bio?: string;
  order: number;
  group: "Regional Engineers" | "Staff" | "Other";
  constructor(node: StaffFields) {
    const _img = get(node, `image`);
    const contactInfo = get(node, `contactInfo`);
    this.name = get(node, `name`);
    this.jobTitle = get(node, `jobTitle`, get(node, `job.title`));
    this.image = _img ? new Img(_img) : undefined;
    const full = get(node, "full");
    this.address = get(full, "address");
    this.addressLinesArr = this.address.split("\n");
    this.contactInfo = contactInfo ? new ContactInfo(contactInfo) : undefined;
    this.bio = get(node, "bio.file.url");
    this.order = get(node, "order");
    this.group = get(node, "group", "Other");
  }
}

export class Staff {
  /**
   * collection of NodeStaffFields results
   */
  list: StaffMember[];
  length: number;
  groups: { [key: string]: StaffMember[] };
  constructor(edges: { node: StaffFields }[]) {
    const staff = Array.isArray(edges)
      ? edges.map((e) => new StaffMember(e.node))
      : [];
    this.list = staff;
    this.length = staff.length;
    this.groups = staff.reduce((groups, member) => {
      const _group = get(member, `group`);
      const group = _group.match(/staff/i)
        ? "Management"
        : "Regional Engineers";
      if (!groups[group]) {
        groups[group] = [];
      }
      groups[group].push(member);
      return groups;
    }, {});
  }
}
