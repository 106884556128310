import * as React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import get from "lodash/get";
import DipraButton from "../components/ui/DipraButton";
import { colors } from "../vars/palette";
import { Type } from "../components/ui/Typography";
// import Img from '../classes/Img';
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
// import StrengthAndDurability from '../components/Layout/Footer/BottomRow/StrengthAndDurability';

const Wrap = styled.div`
  .row {
    padding: 4rem 0;
  }
`;
const ButtonsWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem 0 3rem;
`;

const ImgCol = styled(Col)`
  display: flex;
  align-items: center;
  .gatsby-image-wrapper {
    margin-bottom: 1rem;
  }
`;

const TargetRow = styled.div`
  position: relative;
  z-index: -1;
  top: -5rem;
`;

// const ContentWrap = styled.div`

// `

const ButtonJSX = ({ to, text, marginRight }) => (
  <DipraButton
    link={{ to: to ? to : "#", target: "_self", text: text ? text : "" }}
    color={colors.blue.hex}
    hoverColor={colors.grayBlue.hex}
    textColor={colors.white.hex}
    fontSize="12px"
    bold
    innerPadding=".5rem 2rem"
    style={{ marginRight: marginRight ? marginRight : 0 }}
  />
);

const ContentJSX = ({ isLogo, image, title, copy, buttonTo }) => {
  // const img = new Img(image); // tried using class and it was throwing strange error
  const gatsby = get(image, "gatsbyImageData");
  const alt = get(image, "title");
  const src = get(image, "file.url");
  const imgType = get(image, `file.contentType`, "");
  const isSvg = imgType.match(/svg/);
  return (
    <>
      {isLogo ? (
        <ImgCol lg={7}>
          <StaticImage
            src="../images/logos/DIPR-Logo_RGB-01_.png"
            alt="Ductile Iron Pipe Research Association"
            width={500}
            placeholder={"none"}
            layout={"constrained"}
            transformOptions={{ fit: "contain" }}
            backgroundColor={"transparent"}
            quality={95}
          />
        </ImgCol>
      ) : (
        <ImgCol lg={7}>
          {isSvg ? (
            <img src={src} alt={alt} style={{ margin: `auto` }} />
          ) : (
            <GatsbyImage image={gatsby} alt={alt} />
          )}
        </ImgCol>
      )}
      <Col lg={5}>
        <Type.EngHeadline
          style={{
            color: colors.blue.hex,
            fontWeight: "bold",
            textTransform: "none"
          }}>
          {title && title}
        </Type.EngHeadline>
        <Type.Body style={{ margin: "1rem 0 2rem" }}>{copy && copy}</Type.Body>
        <ButtonJSX to={buttonTo ? buttonTo : "#"} text="DROPBOX" />
      </Col>
    </>
  );
};

const LogoAndTaglinePageContent = (props) => {
  const data = useStaticQuery(graphql`
    query {
      logoContent: contentfulBlockIconCard(
        blockId: { eq: "Logo and Tagline Page - Logo" }
      ) {
        ...NodeBlockIconCard
      }
      taglineContent: contentfulBlockIconCard(
        blockId: { eq: "Logo and Tagline Page - Tagline" }
      ) {
        ...NodeBlockIconCard
      }
    }
  `);

  const logoContent = get(data, "logoContent");
  const taglineContent = get(data, "taglineContent");
  // const logoContent = new RichText(get(data, 'logoContent'));
  // const taglineContent = new RichText(get(data, 'taglineContent'));

  return (
    <Wrap>
      <Container>
        <ButtonsWrap>
          <ButtonJSX to="#logo" text="LOGO" marginRight="5rem" />
          <ButtonJSX to="#tagline" text="TAGLINE" />
        </ButtonsWrap>
        <TargetRow id="logo" />
        <Row>
          <ContentJSX
            isLogo={true}
            image={get(logoContent, "iconImageNoSize")}
            title={get(logoContent, "headline")}
            copy={get(logoContent, "copy.text")}
            buttonTo="#"
          />
        </Row>
        <TargetRow id="tagline" />
        <Row>
          <ContentJSX
            image={get(taglineContent, "iconImageNoSize")}
            title={get(taglineContent, "headline")}
            copy={get(taglineContent, "copy.text")}
            buttonTo="#"
          />
        </Row>
      </Container>
    </Wrap>
  );
};

export default LogoAndTaglinePageContent;
