import * as React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import HubspotForm from 'react-hubspot-form'

const Wrap = styled.div`
  margin-bottom: 4rem;
  h2,
  h3,
  p {
    text-align: center;
    max-width: 800px;
    margin: auto;
    margin-bottom: 1rem;
  }
  .hidden {
    opacity: 0;
    height: 0;
    position: absolute;
    top: -100vh;
  }
`;

const SubscribeForm = () => {
  return (
    <Container>
      <Wrap>
        <h2>Subscribe to DIPRA's E-Newsletters</h2>
        <HubspotForm
          portalId='21124615'
          formId='0be1f0fd-7a2a-498f-827c-cc8565b1dcc8'
          // onSubmit={() => console.log('Submitted!')}
          // onReady={(form) => console.log('Form ready!')}
          loading={<div>Loading...</div>}
        />
      </Wrap>
    </Container>
  );
};

export default SubscribeForm;
