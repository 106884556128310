import * as React from "react";
import styled from "styled-components";
import get from "lodash/get";
import { BREAKPOINT_XL_MIN, Type } from "../../../components/ui/Typography";
import { WmkLink } from "wmk-link";
import { colors } from "../../../vars/palette";
import { MemberCompany } from "../../../classes/MemberCompany";
import { WmkImage } from "wmk-image";

const Wrap = styled.div`
  * {
  }
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  margin: 0.5rem 0;
  .gatsby-image-wrapper {
    width: 162px;
  }
  a {
    color: ${colors.lightGreen.hex};
    text-decoration: underline;
  }
  @media only screen and (max-width: ${BREAKPOINT_XL_MIN}) {
    text-align: center;
    .gatsby-image-wrapper {
      margin: auto;
    }
  }
`;
const AddressWrap = styled.div`
  padding: 1rem 0;
  p {
    margin-bottom: 3px;
  }
`;

const MemberCompanyCard = ({ member }: { member: MemberCompany }) => {
  return (
    <Wrap>
      {member.logo ? <WmkImage image={member.logo} /> : null}
      <Type.H6 style={{ textTransform: "none" }}>{member.name}</Type.H6>
      <AddressWrap>
        {member.address.map((line, i) => (
          <Type.P key={line + i}>{line}</Type.P>
        ))}
      </AddressWrap>
      <ul style={{ listStyleType: "none", padding: 0 }}>
        {member.contact.list.map((con, j) => {
          const isUrl = con.type === "url";
          return (
            <li
              key={con.type + j}
              style={{ padding: `.25rem 0`, fontSize: ".9rem" }}>
              <span>{isUrl ? null : `${con.label} `}</span>
              <WmkLink
                to={con.to}
                tel={con.label.match(/phone|fax/i) ? true : undefined}
                mailto={con.label.match(/email/i) ? true : undefined}
                target={isUrl ? "blank" : undefined}>
                {con.text}
              </WmkLink>
            </li>
          );
        })}
      </ul>
    </Wrap>
  );
};

export default MemberCompanyCard;
